// ----------------------------------------------------------------------
function path(root, sublink) {
    return `${root}${sublink}`;
}
const ROOTS_DASHBOARD = '/dashboard';
// ----------------------------------------------------------------------
export const PATH_AUTH = {
    login: '/login',
    resetPassword: '/forget-password',
};
export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    app: path(ROOTS_DASHBOARD, '/app'),
    profileLabo: path(ROOTS_DASHBOARD, '/profile-labo'),
    profileCabinet: path(ROOTS_DASHBOARD, '/profile-cabinet'),
    branches: path(ROOTS_DASHBOARD, '/branches'),
    draftCommand: path(ROOTS_DASHBOARD, '/draft-commandes'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    consult: path(ROOTS_DASHBOARD, '/consult/:id'),
    order: path(ROOTS_DASHBOARD, '/orders/:id'),
    treatment: path(ROOTS_DASHBOARD, '/treatment/:id'),
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    fleet: {
        root: path(ROOTS_DASHBOARD, '/fleet'),
        categories: path(ROOTS_DASHBOARD, '/fleet/categories'),
        vehicles: path(ROOTS_DASHBOARD, '/fleet/vehicles'),
        leasingContracts: path(ROOTS_DASHBOARD, '/fleet/leasingContracts'),
    },
    config: {
        root: path(ROOTS_DASHBOARD, '/config'),
        options: path(ROOTS_DASHBOARD, '/config/options'),
        materiaux: path(ROOTS_DASHBOARD, '/config/materiaux'),
        types: path(ROOTS_DASHBOARD, '/config/types'),
        article: path(ROOTS_DASHBOARD, '/config/article'),
    },
    archive: {
        root: path(ROOTS_DASHBOARD, '/archive'),
        patient: path(ROOTS_DASHBOARD, '/archive/patient_archive'),
        cabinet: path(ROOTS_DASHBOARD, '/archive/cabinet_archive'),
        order: path(ROOTS_DASHBOARD, '/archive/order_archive'),
    },
    pricing: {
        root: path(ROOTS_DASHBOARD, '/pricing'),
        vehicleGroups: path(ROOTS_DASHBOARD, '/pricing/vehicleGroups'),
        seasons: path(ROOTS_DASHBOARD, '/pricing/seasons'),
    },
    laboratoires: path(ROOTS_DASHBOARD, '/laboratoires'),
    reservation: {
        root: path(ROOTS_DASHBOARD, '/reservation'),
        list: path(ROOTS_DASHBOARD, '/reservation/list'),
        calendar: path(ROOTS_DASHBOARD, '/reservation/calendar'),
    },
    customers: path(ROOTS_DASHBOARD, '/customers'),
    settings: {
        root: path(ROOTS_DASHBOARD, '/settings'),
        agency: path(ROOTS_DASHBOARD, '/settings/agency'),
        users: path(ROOTS_DASHBOARD, '/settings/users'),
        bookableExtras: path(ROOTS_DASHBOARD, '/settings/bookableExtras'),
    },
    patientForm: path(ROOTS_DASHBOARD, '/patient/form/:step/:id?/:treatmentId?'),
    patient: path(ROOTS_DASHBOARD, '/patient'),
    prosthetist: path(ROOTS_DASHBOARD, '/prosthetist'),
    cabinet: path(ROOTS_DASHBOARD, '/cabinet'),
    permission: path(ROOTS_DASHBOARD, '/permission'),
    notifications: path(ROOTS_DASHBOARD, '/notifications'),
    dentist: path(ROOTS_DASHBOARD, '/dentist'),
};
